import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClock,
  faWalking,
  faMapMarked,
  faDollarSign,
  faBan,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO />
    <div id="main">
      <div style={{ background: "#FFF" }}>
        <a href="/">
          <img
            id="logo"
            src={data.file.publicURL}
            alt="Enosh Bible Camp Logo"
          />
        </a>
      </div>
      <div className="diagonal">
        <svg viewBox="0 0 100 10" preserveAspectRatio="none">
          <polygon points="100,0 0,10 0,0" style={{ fill: "#FFF" }} />
        </svg>
        {/* <Link className="button--anchor" to="/registrationform">
          Click For Camper Registration
        </Link> */}
        <Link className="button--anchor" to="/retreatregistrationform">
          Click For Enosh Retreat Registration
        </Link>
      </div>
      <div className="info">
        <p className="header--verse">
          "For whoever calls on the name of the LORD shall be saved."
        </p>
        <span className="header--verse">- Romans 10:13</span>
        <p>
          Enosh Bible Camp exists for the making of disciples. It is our joy and
          privilege to present Christ to every camper as the most amazing person
          they will ever have the privilege of knowing, the Savior each one
          desperately needs, and the One Who is worthy of ALL of our devotion.
          Our prayer and our goal is to see individuals made into zealous,
          mature, Christ-like followers (disciples).
        </p>
        <span>- 1 Corinthians 9:19, Colossians 1:28</span>
      </div>
      <div>
        <svg viewBox="0 0 100 7" preserveAspectRatio="none">
          <polygon points="0,7 100,7 0,0" style={{ fill: "#FFF" }} />
        </svg>
      </div>
      <div className="info info--row info--white">
        <div className="column">
          <p className="icon" style={{ marginLeft: "-1.25em" }}>
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={faClock} size="3x" />
            </span>
          </p>
          <h3>When is Enosh Retreat ?</h3>
          <p style={{ fontWeight: "600" }}>November 15-16 2024</p>
          <p>
            <a
              href="/files/enosh_retreat_2024.pdf"
              target="_blank"
              rel="noopener"
            >
              Click here to view full Enosh Bible Camp retreat details.
            </a>
          </p>
          {/* <h3>When is camp ?</h3>
          <p>9:00 AM - 4:00 PM</p>
          <p style={{ fontWeight: "600" }}>Junior High Week - July 8-12</p>
          <p>6th grade - entering 9th grade in the fall of 2024</p>
          <p style={{ fontWeight: "600" }}>Grade School Week - July 15-19</p>
          <p>8 years old - going into 5th grade</p> */}
        </div>
        <div className="column">
          <p className="icon">
            <FontAwesomeIcon icon={faWalking} size="4x" />
          </p>
          <h3>What happens at camp ?</h3>
          <span>
            Enosh Bible Camp provides a full schedule that includes: games,
            team-sports, lunch, stories, crafts, Bible studies and mentoring for
            kids 8 years old up to 9th grade; regardless of background.
          </span>
        </div>
      </div>
      <div>
        <svg viewBox="0 0 100 5" preserveAspectRatio="none">
          <polygon points="100,0 0,5 0,0" style={{ fill: "#FFF" }} />
        </svg>
      </div>
      <div className="info info--row">
        <div className="column">
          <p className="icon">
            <FontAwesomeIcon icon={faMapMarked} size="3x" />
          </p>
          <h3>Where is the camp ?</h3>
          <span>
            Enosh Bible Camp is held on the spacious grounds of Topeka Gospel
            Chapel located just south of 62nd Street in north Topeka. Sitting on
            a four acre lot with sports fields, and an indoor air conditioned
            facility for meetings and meals; it's a great place to play and
            learn about God by studying the Bible.
          </span>
        </div>
        <div className="column">
          <iframe
            className="googlemap"
            title="Topeka Gospel Chapel Map"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://www.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=5919+NW+Glenwood+Dr,+Topeka,+KS&amp;aq=0&amp;oq=5919+NW+Glenwood+Dr.&amp;sll=39.013054,-95.708031&amp;sspn=0.344121,0.676346&amp;ie=UTF8&amp;hq=&amp;hnear=5919+NW+Glenwood+Dr,+Topeka,+Shawnee,+Kansas+66617&amp;ll=39.153166,-95.668972&amp;spn=0.042933,0.084543&amp;t=m&amp;z=12&amp;output=embed"
          ></iframe>
          <br />
          <small>
            <a
              href="http://www.google.com/maps?f=q&amp;source=embed&amp;hl=en&amp;geocode=&amp;q=5919+NW+Glenwood+Dr,+Topeka,+KS&amp;aq=0&amp;oq=5919+NW+Glenwood+Dr.&amp;sll=39.013054,-95.708031&amp;sspn=0.344121,0.676346&amp;ie=UTF8&amp;hq=&amp;hnear=5919+NW+Glenwood+Dr,+Topeka,+Shawnee,+Kansas+66617&amp;ll=39.153166,-95.668972&amp;spn=0.042933,0.084543&amp;t=m&amp;z=14"
              style={{ color: "#FFF", textAlign: "left" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Larger Map
            </a>
          </small>
        </div>
      </div>
      <div>
        <svg viewBox="0 0 100 15" preserveAspectRatio="none">
          <polygon points="0,15 100,15 0,0" style={{ fill: "#FFF" }} />
        </svg>
      </div>
      <div className="info info--row info--white">
        <div className="column">
          <p className="icon">
            <FontAwesomeIcon icon={faUsers} size="3x" />
          </p>
          <h3>Who will be with my kids ?</h3>
          <p>
            Enosh Bible Camp utilizes the talents of Godly young men and women
            committed to helping kids. They volunteer for the week to spend time
            teaching and encouraging in a way that exemplifies Jesus Christ.
          </p>
          <p>
            Also on site are camp directors, a camp nurse and kitchen staff.
          </p>
        </div>
        <div className="column">
          <p className="icon" style={{ marginLeft: "-1.25em" }}>
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon
                icon={faDollarSign}
                size="3x"
                style={{ marginLeft: ".55em" }}
              />
              <FontAwesomeIcon icon={faBan} size="5x" />
            </span>
          </p>
          <h3>What does it cost ?</h3>
          <span>
            Enosh Bible Camp is a faith-based ministry and is free of charge.
          </span>
        </div>
      </div>
      <div>
        <svg viewBox="0 0 100 10" preserveAspectRatio="none">
          <polygon points="100,0 0,10 0,0" style={{ fill: "#FFF" }} />
        </svg>
      </div>
      <div className="info">
        <h3>Have more questions ?</h3>
        <span>
          <a href="/about">Click here</a> to learn more about the camp and its
          history.{" "}
        </span>
        {/* <br />
        <h3>Registration</h3>
        <span>
          <a href="/registrationform">Click here</a> to register your camper for
          the summer of 2024.
        </span>
        <br /> */}
        <br />
        <p>
          <a
            href="/files/enosh_retreat_2024.pdf"
            target="_blank"
            rel="noopener"
          >
            Click here to view full Enosh Bible Camp retreat details.
          </a>
        </p>
        <br />
        <br />
        <span>Interested in staffing? Contact us below.</span>
        <br />
        <br />
        <h3 id="contact-us">Contact Us</h3>
        <span>
          Contact us by email at{" "}
          <a href="mailto:enoshbiblecamp@gmail.com">enoshbiblecamp@gmail.com</a>
        </span>
        <span className="phonenumber">
          {" "}
          or call{" "}
          <a href="tel:1-918-497-6087" className="phonenumber">
            918-497-6087
          </a>
        </span>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "campenoshlogomini_web.svg" }) {
      publicURL
    }
  }
`

export default IndexPage
